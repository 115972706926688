`use strict`;


export default class Lazyloading {

  constructor() {
  }

  initLazyLoading() {
    let myOptions = {
      rootMargin: '0px',
      threshold: 0.1
    };

    //observe all images with class lazyload
    let images = $('.js-lazyload');

    if ('IntersectionObserver' in window) {
      let observer = new IntersectionObserver(handleIntersection, myOptions);

      images.each(function (key, img) {
        observer.observe(img);
      });

      function handleIntersection(entries, observer) {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            loadImage(entry.target)
          }
        })
      }

      function loadImage(image) {
        if(!image.classList.contains('js-loaded')) {
          let src = image.dataset.src;
          fetchImage(src).then(() => {
            image.src = src;
            image.classList.add('js-loaded');
          })
        }
      }

      function fetchImage(url) {
        return new Promise((resolve, reject) => {
          console.log('fetching');
          let image = new Image();
          image.src = url;
          image.onload = resolve;
          image.onerror = reject;
        });
      }
    } else {
      // IO is not supported.
      // Just load all the images
      images.each(function (key, img) {
        loadImage(img);
      });
    }
  }
}


