`use strict`;

export default class XHR {

  constructor() {
    window.defaultOptions = {
      type: 'POST',
      headers: {
        'X-CSRF-TOKEN': window.Laravel.csrfToken
      },
      context: this,
      dataType: 'json',
      complete: {
      }
    };

    window.options = {};
  }

  setOptions (options) {

    options.error = options.error && function (xhr) {
      const statusCode = xhr.status;
      let res = xhr.responseJSON;
      if (statusCode === 422) {
          let messages = '';
          Object.keys(res.errors).forEach((key) => {
              res.errors[key].forEach((message) => messages += `${message}<br>`);
          });
          $('.js-error-messages').html(messages);
      }
    };

    //add options to complete if needed
    if (options.hasOwnProperty("complete") && typeof options.complete === "function") {
      let completeFunctions = [];

      completeFunctions.push(window.defaultOptions.complete);
      completeFunctions.push(options.complete);

      options.complete = completeFunctions;
    }

    Object.assign(window.options, window.defaultOptions, options);

    //check if success function is set
    if (!window.options.hasOwnProperty("success") || typeof window.options.success !== "function") {
      throw new Error ('"success" was not defined.');
    }

    //check if error function is set
    if (!window.options.hasOwnProperty("error") || typeof window.options.error !== "function") {
      throw new Error('"error" was not defined.');
    }

    //check if url is set
    if (!window.options.hasOwnProperty("url") || typeof window.options.url !== "string") {
      throw new Error('"url" was not defined.');
    }

    //check if data is set
    if (!window.options.hasOwnProperty("data")) {
      throw new Error('"data" was not defined.');
    }

  }

  execute() {
    $.ajax(window.options);
  }
}